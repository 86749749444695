<template>
  <!-- 分公司工地材料报表 -->
  <el-card id="printContent">
    <div slot="header" style="height:30px;line-height:30px;text-align:left;">
      <div style="display: inline-block;">
        <el-button type="primary" size="small" @click="printContent()">打印</el-button>
        <download-excel style="display:inline-block" :data="tableData" :fields="json_fields" :header="title" :footer="footer" :name="title + '.xlsx'">
          <el-button type="success" size="small">导出</el-button>
        </download-excel>
      </div>
      <div style="width:70px;display: inline-block;"></div>
      <div style="display: inline-block;">
        <el-date-picker type="date" :picker-options="pickerOptions" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="thedate" style="width: 150px;">
        </el-date-picker>
        <el-select v-model="company_code" placeholder="请选择分公司" v-if="currentUserCompany === '00'" @change="getCompany">
          <el-option v-for="item in companys_options" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <el-button ref="SearchBtn" type="primary" @click="search">查询</el-button>
        <span style="font-weight:bold;font-size: 20px;color: red;">{{ searchStart }} </span>
        <span style="font-weight:bold;font-size: 20px;color: red;">{{ searchEnd }} </span>
      </div>
      <div style="width:50px;display: inline-block;"></div>
      <span style="font-weight:bold;font-size: 20px;color: blue;">{{ thedate }} 在工地材料报表</span>
    </div>
    <el-table
      v-loading="loading"
      :data="tableData"
      height="750"
      border
      stripe
      highlight-current-row
      :header-row-style="TableHeaderRowStyle"
      :header-cell-style="TableHeaderCellStyle"
      :row-style="TableRowStyle"
      :cell-style="TableCellStyle"
      style="width:100%"
    >
      <!-- <el-table-column type="index" width="45" fixed> </el-table-column> -->
      <el-table-column v-for="item in labels" :key="item" :label="item" :prop="item" :width="item === '合同' ? 150 : 70" :fixed="item === '合同'">
      </el-table-column>
    </el-table>
  </el-card>
</template>

<script>
import utils from "@/assets/js/utils"

export default {
  data() {
    return {
      searchStart: "",
      searchEnd: "",
      title: "",
      footer: "",
      json_fields: {
        合同: "合同",
        "575-6米": "575-6米",
        "575-9米": "575-9米",
        "575-12米": "575-12米",
        "575-15米": "575-15米",
        "400-9米": "400-9米",
        "L400-6米": "L400-6米",
        "L400-9米": "L400-9米",
        "L400-12米": "L400-12米",
        "角板575-6米": "角板575-6米",
        "角板575-9米": "角板575-9米",
        "角板575-12米": "角板575-12米",
        "角板L400-12米": "角板L400-12米",
        "角板L400-9米": "角板L400-9米",
        "角板L400-6米": "角板L400-6米",
        "角板400-9米": "角板400-9米",
        "角板400-12米": "角板400-12米",
        "斜板575-6米": "斜板575-6米",
        "斜板575-9米": "斜板575-9米",
        "斜板575-12米": "斜板575-12米",
        "牙条角板L400-9米": "牙条角板L400-9米",
        "牙条角板L400-12米": "牙条角板L400-12米",
        "牙条角板L400-6米": "牙条角板L400-6米",
        "牙条角板575-6米": "牙条角板575-6米",
        "牙条角板575-9米": "牙条角板575-9米",
        小计: "小计"
      },
      thedate: utils.getDate(),
      tableData: [],
      sumChuku: 0,
      sumHuiku: 0,
      labels: [],
      loading: false,
      currentUserCompany: utils.getUserCookie().company.code,
      companys_options: [],
      company_code: "",
      company_name: "",
      pickerOptions: {
        // 限制只能选择今天之前的日期
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      TableHeaderRowStyle() {
        return "height:35px"
      },
      TableHeaderCellStyle() {
        return "padding-bottom:4px;padding-top:4px;font-size:15px;font-weight:700;color:#000;"
      },
      TableRowStyle() {
        return "height:35px"
      },
      TableCellStyle() {
        return "padding-bottom:4px;padding-top:4px;font-size:15px;color:#000;"
      }
    }
  },
  computed: {
    totalKucun: function() {
      return Number(this.sumKucun.zong_kucun) + Number(this.sumKucun.zong_ingongdi_amount) + Number(this.sumKucun.zong_onroad_amount)
    }
  },
  methods: {
    //获取分公司列表
    getCompanyList() {
      this.$axios.get("/getCompanyList/").then(res => {
        console.log("companys", res.data)
        res.data.forEach(element => {
          let company = {}
          company.label = element.name
          company.value = element.code
          this.companys_options.push(company)
        })
        console.log("companys_options", this.companys_options)
      })
    },
    getTableData() {
      if (this.company_code === "00") return
      this.tableData = []
      this.loading = true
      this.$axios
        .get("/htIngongdiBaobiao/", {
          params: {
            date: this.thedate,
            company: this.company_code //公司编号code
          }
        })
        .then(res => {
          if (res.data.code === "1000") {
            this.tableData = res.data.tableData
            this.labels = res.data.labels
            this.title = this.thedate + " " + this.company_name + "在工地材料报表"
            this.searchStart = ""
            this.loading = false
          } else {
            console.log("没有数据！")
          }
        })
        .catch(function(error) {
          console.log(error)
          this.tableData = []
          this.loading = false
        })
      // this.$nextTick(() => {
      //   this.searchBtn = "查询"
      // })
    },
    search() {
      if (this.company_code.length != 0) {
        this.searchStart = "正在查询，请稍等..."
        // this.$forceUpdate()
        console.log("this.company_code:", this.company_code)
        this.getTableData()
      } else {
        this.$message({ type: "warning", message: "请选择分公司", duration: 2000 })
      }
    },
    //获取选择的分公司
    getCompany() {
      // utils.setCompanyCookie(this.company_code)

      let cur_company_code = this.company_code
      let cur_company_name = ""
      console.log("cur_company_code", cur_company_code)
      console.log("companys_options:", this.companys_options)
      this.companys_options.forEach(function(item) {
        // console.log('label:', item.label)
        // console.log('value:', item.value)
        // console.log('cur_company_code', cur_company_code)
        if (item.value === cur_company_code) {
          cur_company_name = item.label
          // console.log('cur_company_name', cur_company_name)
        }
      })
      this.company_name = cur_company_name
      console.log("cur_company_name......:", cur_company_name)
    },
    printContent() {
      utils.printContent("printContent")
    }
  },
  created() {
    this.getCompanyList()
  }
}
</script>

<style scoped>
.footer {
  margin-top: 10px;
  margin-left: 50px;
}
/deep/ .el-table td,
/deep/ .el-table th.is-leaf,
/deep/ .el-table--border,
/deep/ .el-table--group {
  border-color: black;
}
/deep/ .el-table--border::after,
/deep/ .el-table--group::after,
/deep/ .el-table::before {
  background-color: black;
}
</style>

<style>
.el-table .cell {
  white-space: pre-line;
  text-align: center;
}
</style>
